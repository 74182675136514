import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="overcoming-obstacles" mdxType="Subpage">
      <h3>{`Overcoming Obstacles`}</h3>
      <Callout lead={true} color="alternate" mdxType="Callout">
        <p>{`Read Chapter 5 from `}<a parentName="p" {...{
            "href": "https://learning.oreilly.com/library/view/designing-for-emotion/9780133052954/"
          }}>{`Walter`}</a>{`.`}</p>
      </Callout>
    </Subpage>
    <Subpage slug="forgiveness" mdxType="Subpage">
      <h3>{`Forgiveness`}</h3>
      <Callout lead={true} color="alternate" mdxType="Callout">
        <p>{`Read Chapter 6 from `}<a parentName="p" {...{
            "href": "https://learning.oreilly.com/library/view/designing-for-emotion/9780133052954/"
          }}>{`Walter`}</a>{`.`}</p>
      </Callout>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      